import { defineStore } from 'pinia'
import { checkIsOnOrAfter } from '@/utils/dateTime'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: {},
    salesForceAccountId: null,
    cognitoUser: {},
    accountManager: {},
    mfa: {
      preference: null,
      isFetched: false,
      setup: null,
      showBanner: true
    }
  }),
  getters: {
    userName: state => `${state.user?.firstName} ${state.user?.lastName}`,
    userFirstName: state => state.user?.firstName,
    userLastName: state => state.user?.lastName,
    userEmail: state => state.user?.email,
    userPartyId: state => state.user?.partyId,
    accountManagerId: state => state.accountManager?.id,
    isMethodEnabled: state => {
      return method => !!state.mfa.preference?.enabled?.includes(method)
    },
    isMethodPreferred: state => {
      return method => state.mfa.preference?.preferred === method
    },
    mfaSetup: state => {
      return state.mfa.setup
    },
    hasMfaEnabled() {
      return this.isMethodEnabled('TOTP') || this.isMethodEnabled('SMS')
    },
    hasBothMfaEnabled() {
      return this.isMethodEnabled('TOTP') && this.isMethodEnabled('SMS')
    },
    showMfaBanner(state) {
      return (
        state.mfa.showBanner &&
        !this.hasMfaEnabled &&
        !checkIsOnOrAfter(new Date(), new Date(2024, 10, 4))
      )
    },
    isMfaFetched: state => {
      return state.mfa?.isFetched
    },
    showMfaInvite(state) {
      return (
        state.mfa?.isFetched &&
        !this.hasMfaEnabled &&
        !checkIsOnOrAfter(new Date(), new Date(2024, 10, 4))
      )
    },
    isFlipTVAccount: state => {
      const env = import.meta.env.VITE_APP_ENVIRONMENT

      if (env === 'staging') {
        return (
          state.user?.partyId === import.meta.env.VITE_APP_FLIPTV_ID_STAGING
        )
      }

      if (env === 'production') {
        return (
          state.user?.partyId === import.meta.env.VITE_APP_FLIPTV_ID_PRODUCTION
        )
      }

      return false
    }
  },
  actions: {
    storeAccountManager(payload) {
      if (!payload.phone) {
        payload.phone = '1300 333 200'
      }

      this.accountManager = payload
    },
    setCognitoUser(payload) {
      this.cognitoUser = payload
    },
    setMFAPreference(payload) {
      this.mfa.preference = payload
    },
    setIsMfaFetched(payload) {
      this.mfa.isFetched = payload
    },
    setMfaSetup(payload) {
      this.mfa.setup = payload
    },
    setMfaBanner(payload) {
      this.mfa.showBanner = payload
    }
  }
})
