/*
  Provides a simple way of making API requests (using fetch api) while integrating the following features:
  - The ability to serve up mock data
  - Custom data transformations
  - Automatic spinners during requests
  - Automatic error notifications
  - Automatic endpoint path resolution
  - Default headers

  EXAMPLE USAGE: -----------------------------------

    import API from '@/api/apiHelper'

    API.get(`/customer/1234`).then(data => {
      // Do something with data...
    })

    API.post(`/customer`, { data })
*/
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth'
import { Notify } from 'quasar'
import router from '@/router'

// Default config which can be overriden by using apiHelper.config({})
let config = {
  mockResponseTime: 500
}
let incompleteRequests = 0 // Tracks number of requests currently running in order to know when to hide the spinner (for requests that are using automatic spinner).

export default {
  /*
    Allows the initial setting of API configuration parameters below. Ideally this should be set from main.js.
    - apiRoot <string>: Default <empty>. If using a primary api, its root should be specified here (e.g. https://test.api/v1). Then individual requests can use relative endpoints.
    - mockResponseTime <integer>: Default 500. Number of milliseconds delay between the request and the mock response. This can be useful for making the mock experience more realistic, giving a chance to see things like spinners and "pop-in".
    - headers <json>: Optional default headers. Note that any headers specified in the request will take precedence.
    - onShowSpinner(message) {} <function>: Callback is fired when a request starts.
        - message <string>: A message that can accompany the spinner, e.g. "Updating..."
    - onHideSpinner() {} <function>: Callback is fired when all outstanding requests have completed.
  */
  config(data) {
    config = {
      apiRoot: data.apiRoot || config.apiRoot,
      mockResponseTime:
        data.mockResponseTime >= 0
          ? data.mockResponseTime
          : config.mockResponseTime,
      headers: data.headers || config.headers,
      onShowSpinner: data.onShowSpinner,
      onHideSpinner: data.onHideSpinner
    }
  },

  get(endpoint, params) {
    // Wraps the request() function. See request() for params help.
    params = params || {}
    params.method = 'get'
    params.endpoint = endpoint
    return request(params)
  },

  post(endpoint, params) {
    // Wraps the request() function. See request() for params help.
    params = params || {}
    params.method = 'post'
    params.endpoint = endpoint
    return request(params)
  },

  put(endpoint, params) {
    // Wraps the request() function. See request() for params help.
    params = params || {}
    params.method = 'put'
    params.endpoint = endpoint
    return request(params)
  },

  patch(endpoint, params) {
    // Wraps the request() function. See request() for params help.
    params = params || {}
    params.method = 'patch'
    params.endpoint = endpoint
    return request(params)
  },

  delete(endpoint, params) {
    // Wraps the request() function. See request() for params help.
    params = params || {}
    params.method = 'delete'
    params.endpoint = endpoint
    return request(params)
  }
}

// Local helper functions ---------------------------------------------

async function request({
  method,
  endpoint,
  params,
  data,
  isFormData,
  spinner = true,
  headers,
  mock,
  transform,
  plaintext,
  silent,
  version = 'v0',
  signal,
  baseURL
}) {
  /*
    Makes an API request using FetchAPI and returns response data
      method <string> - Required: These methods are currently supported: get | post | put
      endpoint <string> - Required: e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
      params <object> - Optional. Any key/values specified here will be serialized as url params.
      data <json> - Optional payload data for FetchAPI
      isFormData <boolean> - Optional flag to specify if request is multipart/form-data
      spinner <boolean> - Optional flag to specify if spinner should appear during the request. Default is "true".
      headers <object> - Optional FetchAPI headers to use instead of defaults
      mock <array | object> - Optional mock data (for when an api doesn't yet exist). When specified, this data will be returned instead of making a real request (see "mockResponseTime" for adjusting the delay).
      transform <function(data)> - Optional data transformation. Function receives response data and must return transformed response data.
      plaintext <boolean>:  Optional flag to specify if response should be treated as text instead of json. Default is "false"
      silent <boolean>:  Optional. If true, api errors will not automatically be displayed as on-screen notifications.
      version <string>: Optional. e.g. "v1". Will be placed between the API Root and URL endpoint. Default is "v0".
      signal <AbortSignal>: Optional. Can be used to communicate with/abort an asynchronous operation as desired.
      baseURL <string>: Optional. Provide when hitting non infinity/bc-backend endpoint.
  */

  if (spinner) {
    showSpinner()
  }

  // Return mock data if endpoint is setup to do so...
  let mockData = getMockData(mock, transform)
  if (mockData) {
    return new Promise(resolve =>
      setTimeout(() => {
        if (spinner) {
          hideSpinner()
        }
        resolve(mockData)
      }, config.mockResponseTime)
    )
  }

  try {
    await getCurrentUser()
  } catch (error) {
    router.push('/')
    return
  }

  const asyncHeaders = await getHeaders(isFormData)

  // Otherwise make real api request...
  const request = new Request(
    resolveEndpoint(endpoint, params, version, baseURL),
    {
      method: method.toUpperCase(),
      body: isFormData || plaintext ? data : JSON.stringify(data),
      headers: new Headers(headers || asyncHeaders),
      signal
    }
  )

  try {
    const response = await fetch(request)
    const responseData = plaintext
      ? await response.text()
      : await response.json()
    if (spinner) {
      hideSpinner()
    }
    if (response.status === 200 || response.status === 201) {
      if (!plaintext && is200error(responseData, silent)) {
        return null
      }
      return transform ? transform(responseData) : responseData
    } else {
      const message = plaintext
        ? JSON.parse(responseData)?.message
        : responseData.message
      throw new Error(message, {
        cause: { code: response.status }
      })
    }
  } catch (e) {
    if (spinner) {
      hideSpinner()
    }
    if (!silent) {
      notifyError(e.message)
    }
    //throw error with exception message too
    throw new Error(`Something went wrong on api server: ${e.message}`, {
      cause: { code: e?.cause?.code || e?.code || 0 }
    })
  }
}

async function getHeaders(isFormData = false) {
  try {
    const session = await fetchAuthSession()
    const jwtToken = session.tokens.accessToken.toString()

    let headers = {
      Authorization: `Bearer ${jwtToken}`
    }

    if (!isFormData) {
      headers = {
        ...headers,
        'Content-Type': 'application/json'
      }
    }
    return headers
  } catch (err) {
    router.push('/')
  }
}

function is200error(responseData, silent) {
  // When the request is successful (200) we still need to detect if the server has
  // returned an error response.
  if (
    responseData &&
    responseData.error &&
    Object.keys(responseData).length <= 2
  ) {
    if (!silent) {
      notifyError(responseData.message || responseData.error)
    }
    return true
  }
}

function resolveEndpoint(endpoint, urlParams, version, baseURL) {
  /*
    Endpoints can be full or relative. If relative, they will automatically
    be prefixed with the API root. The opening slash is optional for
    relative endpoints.
  */
  if (
    endpoint.indexOf('http') === 0 ||
    endpoint.indexOf('https') === 0 ||
    endpoint.indexOf('//') === 0
  ) {
    return endpoint
  }
  if (endpoint.indexOf('/') !== 0) {
    endpoint = `/${endpoint}`
  }
  if (urlParams) {
    // If urlParams object specified, serialize to url params...
    endpoint =
      endpoint +
      (endpoint.includes('?') ? '&' : '?') +
      Object.entries(urlParams)
        .map(p => `${p[0]}=${p[1]}`)
        .join('&')
  }
  return (baseURL || config.apiRoot) + version + endpoint
}

function notifyError(message) {
  Notify.create({
    type: 'negative',
    message: `Could not complete action: ${message}`,
    timeout: 7500
  })
}

function showSpinner() {
  incompleteRequests++
  if (typeof config.onShowSpinner === 'function') {
    config.onShowSpinner()
  }
}

function hideSpinner() {
  /*
    Unless turned off, each request shows a spinner until complete. However each request
    can't just hide the spinner when complete because there could be other incomplete requests
    still running that need the spinner. So a counter is used to keep track of incomplete requests,
    and the spinner is only hidden after all requests have completed (incompleteRequests = 0)
  */
  if (incompleteRequests) {
    incompleteRequests--
  }
  if (!incompleteRequests && typeof config.onHideSpinner === 'function') {
    config.onHideSpinner()
  }
}

function getMockData(mockData, transform) {
  /*
      mockData <array | object> - Optional mock data.
      transform <function> - Optional api data transformation function
  */
  if (!mockData) {
    return null
  }
  try {
    if (transform) {
      mockData = transform(mockData)
    }
    return JSON.parse(JSON.stringify(mockData)) // Return clone of mock data, otherwise it can be mutated.
  } catch (e) {
    return null
  }
}
